var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.title,"logo":_vm.logo},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.future_games'),
            icon: 'tim-icons icon-calendar-60',
            path: '/upcoming-games'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.games'),
            icon: 'tim-icons icon-puzzle-10',
            path: '/games'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.photos'),
            icon: 'tim-icons icon-camera-18',
            path: '/photos'
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.myProfile'),
            icon: 'tim-icons icon-settings-gear-63',
            path: '/settings'
          }}})]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }